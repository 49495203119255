<template>
  <div>
    <template v-if="cardStatus">
      <b-card title="Müşteri KVKK Onayı">
        <kvkk-form
          :customer-id="customerId"
          :refresh-data="refreshData"
        />
      </b-card>
    </template>
    <template v-else>
      <kvkk-form
        :customer-id="customerId"
        :refresh-data="refreshData"
      />
    </template>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import KvkkForm from '@/layouts/components/common/KvkkForm.vue'

export default {
  name: 'KvkkInline',
  components: {
    BCard,
    KvkkForm,
  },
  props: {
    cardStatus: {
      type: Boolean,
      default: true,
    },
    refreshData: {
      type: Function,
      required: true,
    },
    customerId: {
      // eslint-disable-next-line vue/require-prop-type-constructor,no-bitwise
      type: String | Number,
      required: true,
    },
  },
}
</script>
