<template>
  <b-form-group
    v-if="models[Number(dataItem.id_com_brand)].length > 0"
    label="Teşekkür Mektubu Araç Modeli"
    label-for="model"
  >
    <validation-provider
      #default="{ errors }"
      name="Teşekkür Mektubu Araç Modeli"
      rules="required"
    >
      <v-select
        id="model"
        v-model="dataItem.modelType"
        :options="models[Number(dataItem.id_com_brand)]"
        label="title"
        :reduce="item => item.id"
        placeholder="Seçiniz"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { BFormGroup } from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { required } from '@validations'
import vSelect from 'vue-select'

export default {
  name: 'EmailAddress',
  components: {
    vSelect,
    ValidationProvider,
    BFormGroup,
  },
  data() {
    return {
      required,
      models: {
        1: [
          { id: 1, title: 'Binek Modeller' },
          { id: 2, title: 'Berlingo (LCDV kodu 1CK9 ile başlayan otomobiller) (5 koltuklu)' },
          { id: 3, title: 'Jumpy Spacetourer (LCDV kodu 1CK0 ile başlayan otomobiller) (8+1 koltuklu)' },
          { id: 4, title: 'Berlingo Van (LCDV kodu 2CK9 ile başlayan ticari otomobiller)' },
          { id: 5, title: 'Jumpy (LCDV kodu 2CK0 ile başlayan ticari otomobiller)(Panel Van, City Van)' },
          { id: 6, title: 'Jumper (LCDV kodu 2CU9 ile başlayan ticari otomobiller)' },
          { id: 7, title: 'AMI' },
        ],
        2: [
          { id: 1, title: 'Tüm Modeller' },
        ],
        3: [
          { id: 1, title: 'Diğer Modeller' },
          { id: 2, title: '3 Yıl Sınırsız Km Garanti' },
          { id: 3, title: '5 Yıl 100.000 Km Garanti' },
          { id: 4, title: '7 Yıl 80.000 Km Garanti' },
          { id: 5, title: 'H100' },
        ],
        4: [
          { id: 1, title: 'K2500 Bongo' },
          { id: 2, title: 'Diğer Modeller' },
        ],
        5: [
          { id: 1, title: 'Tüm Modeller' },
        ],
        13: [
          { id: 1, title: 'Space Star - L200' },
        ],
      },
    }
  },
  computed: {
    locations() {
      return this.$store.getters['locations/getLocations']
    },
    dataItem() {
      return this.$store.getters['saleDelivery/dataItem']
    },
  },
}
</script>
